import React from 'react';

import { VariantProps } from 'class-variance-authority';
import { ChevronDown, X } from 'lucide-react';

import { Separator } from '@components/ui/separator';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

import { multiSelectVariants } from './multi-select';

interface BadgesProps extends VariantProps<typeof multiSelectVariants> {
  selectedOptions: {
    label: string;
    value: string;
  }[];
  maxItemCount?: number;
  maxItemDisplayableLength?: number;
  placeholder?: string;
  clickEvents?: {
    onItemCircleClick?: (
      event: React.MouseEvent,
      option: {
        label: string;
        value: string;
      }
    ) => void;
    onExtraOptionsCircleClick?: (event: React.MouseEvent) => void;
    onCrossClick?: (event: React.MouseEvent) => void;
  };
}

export const GroupedBadges = ({
  selectedOptions,
  clickEvents,
  maxItemCount = 1,
  maxItemDisplayableLength = 10,
  variant = 'pastelBlue',
  placeholder = 'Select options'
}: BadgesProps) => {
  return selectedOptions.length > 0 ? (
    <div className="flex h-full w-full items-center justify-between">
      <div className="flex flex-wrap items-center">
        {selectedOptions.slice(0, maxItemCount).map((option) => {
          return (
            <Badge
              key={option.value}
              className={cn(multiSelectVariants({ variant }))}
            >
              {option.label.length > maxItemDisplayableLength
                ? option.label
                    .substring(0, maxItemDisplayableLength)
                    .concat('...')
                : option.label}
              {clickEvents && clickEvents.onItemCircleClick && (
                <X
                  className="ml-2 h-4 w-4 cursor-pointer"
                  onClick={(event: React.MouseEvent) => {
                    clickEvents.onItemCircleClick!(event, option);
                  }}
                />
              )}
            </Badge>
          );
        })}
        {selectedOptions.length > maxItemCount && (
          <Badge className={cn(multiSelectVariants({ variant }))}>
            {`+ ${selectedOptions.length - maxItemCount} more`}
            {clickEvents && clickEvents.onExtraOptionsCircleClick && (
              <X
                className="ml-2 h-4 w-4 cursor-pointer"
                onClick={clickEvents.onExtraOptionsCircleClick}
              />
            )}
          </Badge>
        )}
      </div>
      {clickEvents && clickEvents.onCrossClick && (
        <div className="flex items-center justify-between">
          <X
            className="mx-2 h-4 cursor-pointer text-muted-foreground"
            onClick={clickEvents.onCrossClick}
          />
          <Separator orientation="vertical" className="flex h-full min-h-6" />
          <ChevronDown className="mx-2 h-4 cursor-pointer text-muted-foreground" />
        </div>
      )}
    </div>
  ) : (
    <div className="mx-auto flex h-full w-full items-center justify-between">
      <span className="mx-3 text-sm text-muted-foreground">{placeholder}</span>
      {clickEvents && (
        <ChevronDown className="mx-2 h-4 cursor-pointer text-muted-foreground" />
      )}
    </div>
  );
};
