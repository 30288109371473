import { ChangeEvent, useState } from 'react';

import { AppWindow, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AtomReference, DNDBlock, TIBlocks } from 'shared';
import { toast } from 'sonner';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { InteractionZone } from '@components/dnd/base/interaction';
import StateMenu from '@components/stateMenu';

import { useAtom } from '@hooks/useAtom';
import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import EndControlsFB from '@library/formBuilder/endControls';
import { isTIBlocks } from '@library/traceInfo';
import { Checkbox, FormLabel, Modal, ModalClose } from '@mui/joy';

import {
  AddWidgetButton,
  LabeledVariable,
  TOWidgetMessage
} from '../blocks.style';
import { initialTIWindowData } from './window.data';

export const WindowIcon: LucideIcon = AppWindow;

const Window = (block: DNDBlock) => {
  const workflowId = useParams()[ParamsList.WorkflowId] as string;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { dndStore, atomStore } = useStores();

  const dataItem = useAtom({
    type: 'TI_Window',
    sourceId: block.id,
    parentId: workflowId,
    parentKind: 'traceInfo',
    initialData: initialTIWindowData
  });

  const { process } = useProcess();

  if (!dataItem) return <></>;

  if (!process) return <></>;

  const traceInfoDnd = dndStore.get(process.workflows[0]?.traceInfoDndId);
  if (!traceInfoDnd) return <></>;

  const dndPath = `${block.path}:window`;

  const onVariableSelected = (selectedVariableRef: Maybe<AtomReference>) => {
    if (!selectedVariableRef) return;

    const selectedVariable = atomStore.getAtomById(
      selectedVariableRef.dataItemId,
      selectedVariableRef.blockType as TIBlocks
    );

    if (!selectedVariable || selectedVariable instanceof Error) {
      throw newError('WIN-1234', 'Variable not found');
    }

    if (!selectedVariable.variableInfo) {
      throw newError('WIN-4321', 'Selected variable has no variable infos');
    }

    const TIBlockType = `TI_${selectedVariable.variableInfo.resolvedType}`;

    if (!isTIBlocks(TIBlockType)) {
      toast.info('Cannot select this type of variable yet.');
      throw newError(
        'WIN-z123jo',
        'Selected variable resolved type is not a TI block'
      );
    }

    const newTIBlock = traceInfoDnd.createDndBlock(TIBlockType, '');

    newTIBlock.other.selectedVariableRef = selectedVariableRef;

    // The new TI block is added to the window
    const stateWithNewTIBlock = traceInfoDnd.insertBlockInState(
      dndPath,
      traceInfoDnd.state,
      newTIBlock
    );

    traceInfoDnd.setState(stateWithNewTIBlock);

    setTimeout(() => {
      const newTIBlockAtom = atomStore.get(newTIBlock.atomId);

      if (!newTIBlockAtom) {
        throw newError('WIN-0NmZL', 'New TI block atom not found');
      }

      newTIBlockAtom.addReferenceToAtom(selectedVariable.id);
      newTIBlockAtom.addReferenceToAtom(dataItem.id);
    }, 500);
  };

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dataItem.data.title = event.target.value;
  };

  return (
    <>
      <BlockBase
        dndBlock={block}
        title={dataItem.data.title}
        dataItem={dataItem}
        icon={WindowIcon}
        endControls={
          <EndControlsFB
            hasRequired={false}
            dndBlock={block}
            dataItem={dataItem}
          />
        }
      >
        <Column $width="200px">
          <InputField
            label="Window title"
            value={dataItem.data.title}
            onChange={onTitleChange}
            placeholder="Aa"
          />
        </Column>
        <Column>
          <Column $width="200px">
            <LabeledVariable>
              <FormLabel>Configuration</FormLabel>
              <Checkbox
                label="Collapsable window"
                variant="outlined"
                color="neutral"
                size="sm"
                checked={dataItem.data.isCollapsable === true}
                onChange={() => {
                  dataItem.data.isCollapsable = !dataItem.data.isCollapsable;
                }}
              />
            </LabeledVariable>
          </Column>
        </Column>
        <div data-tag="widget-list" style={{ width: '100%' }}>
          <Column>
            <FormLabel sx={{ fontSize: '12px' }}>Widgets</FormLabel>
            <AddWidgetButton
              size="sm"
              value="Add a widget"
              onClick={() => setOpenModal(true)}
            />
          </Column>

          {!block.props?.window?.length ? (
            <TOWidgetMessage>
              No widgets. Click on the above button to add a widget!
            </TOWidgetMessage>
          ) : (
            <InteractionZone
              path={dndPath}
              dndBlock={block.props?.window ?? []}
            />
          )}
        </div>
      </BlockBase>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '70px'
        }}
      >
        <>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <StateMenu
            onSelected={onVariableSelected}
            onClose={() => setOpenModal(false)}
          />
        </>
      </Modal>
    </>
  );
};

export default observer(Window);
