import { observer } from 'mobx-react';
import { NumberWidgetSchema, SharedSchema } from 'shared';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@components/ui/card';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';
import { RadioGroup, RadioGroupItem } from '@components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';

import { AtomModel } from '@models/atom.model';

import { InputInnerLabel } from '@atoms/InputInnerLabel';
import { ColumnElement } from '@atoms/columnElement';
import { ExempleTooltip, InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';

import { parseWithZod } from '@/utils/parseZodSchema';

import { NumberWidgetConfig } from './number.widget.config';

type Props = {
  settingsTitle?: string;
  options: NumberWidgetSchema.FormatOptions;
  onOptionsChange: (options: NumberWidgetSchema.FormatOptions) => void;
  atomId: AtomModel['id'];
};

export const NumberWidgetSettings = observer((props: Props) => {
  const onStyleChange = (style: NumberWidgetSchema.Style) => {
    props.onOptionsChange({
      ...props.options,
      currency: style === 'currency' ? props.options.currency : undefined,
      currencyDisplay:
        style === 'currency' ? props.options.currencyDisplay : undefined,
      style: style
    });
  };

  const settingsTitle = props.settingsTitle ?? 'Number display format settings';

  return (
    <Card>
      <CardHeader>
        <CardTitle>{settingsTitle}</CardTitle>
        <CardDescription>Additional format settings</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-6">
          <div className="flex flex-wrap gap-6">
            <Column $width="200px" $gap="1rem">
              <ColumnElement>
                <Label htmlFor={`style-${props.atomId}`}>Style</Label>
                <Select
                  onValueChange={onStyleChange}
                  value={props.options.style ?? 'decimal'}
                >
                  <SelectTrigger id={`style-${props.atomId}`}>
                    <SelectValue
                      placeholder={renderStyleValue(
                        props.options.style ?? 'decimal'
                      )}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {NumberWidgetConfig.STYLE_LIST.map((style) => (
                        <SelectItem value={style} key={style}>
                          {renderStyleValue(style)}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </ColumnElement>

              <ColumnElement>
                <SwitchV2
                  id={`use-grouping-${props.atomId}`}
                  checked={props.options.useGrouping ?? true}
                  onCheckedChange={(useGrouping) =>
                    props.onOptionsChange({
                      ...props.options,
                      useGrouping: useGrouping
                    })
                  }
                  labelText="Use grouping"
                  tooltip={
                    <InfoTooltip
                      title="Use grouping"
                      description="Whether to use grouping separators, such as thousands separators."
                    />
                  }
                />
              </ColumnElement>
            </Column>
            {props.options.style === 'currency' && (
              <>
                <Column $width="200px">
                  {props.options.style === 'currency' && (
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor={`currency-${props.atomId}`}>
                        Currency
                      </Label>
                      <Input
                        id={`currency-${props.atomId}`}
                        placeholder='Ex: "USD"'
                        value={props.options.currency}
                        onChange={(e) =>
                          props.onOptionsChange({
                            ...props.options,
                            currency: e.target.value
                          })
                        }
                      />
                    </div>
                  )}
                </Column>
                <Column>
                  <Label htmlFor={`currency-display-${props.atomId}`}>
                    Currency display
                  </Label>

                  <RadioGroup
                    id={`currency-display-${props.atomId}`}
                    value={
                      props.options.currencyDisplay ??
                      ('symbol' satisfies NumberWidgetSchema.CurrencyDisplay)
                    }
                    onValueChange={(currencyDisplay) =>
                      props.onOptionsChange({
                        ...props.options,
                        currencyDisplay:
                          currencyDisplay as NumberWidgetSchema.CurrencyDisplay
                      })
                    }
                  >
                    {NumberWidgetConfig.CURRENCY_DISPLAY_LIST.map(
                      (currencyDisplay) => (
                        <div
                          className="flex items-center space-x-2"
                          key={currencyDisplay}
                        >
                          <RadioGroupItem
                            value={currencyDisplay}
                            id={currencyDisplay}
                          />
                          <Label
                            htmlFor={currencyDisplay}
                            className="font-normal cursor-pointer"
                          >
                            {
                              NumberWidgetConfig.CurrencyToInfoMap[
                                currencyDisplay
                              ].name
                            }
                          </Label>
                        </div>
                      )
                    )}
                  </RadioGroup>
                </Column>
              </>
            )}
          </div>
          <div className="flex flex-wrap gap-6">
            <Column>
              <div className="flex items-center gap-2">
                <Label htmlFor={`min-fraction-${props.atomId}`}>
                  Fraction digits
                </Label>
                <InfoTooltip
                  title="Fraction digits"
                  description="Leave blank to use the default value."
                  additionalContent={
                    <>
                      <ExempleTooltip
                        exampleName="Min fraction digits 3"
                        input="12.3"
                        output="12.300"
                      />
                      <ExempleTooltip
                        exampleName="Max fraction digits 3"
                        input="12.3459"
                        output="12.346"
                      />
                    </>
                  }
                />
              </div>

              <div className="flex flex-col justify-between w-full gap-2 align-top">
                <InputInnerLabel
                  innerLabel="Min"
                  type="number"
                  step={1}
                  min={0}
                  id={`min-fraction-${props.atomId}`}
                  placeholder="Ex: 2"
                  value={props.options.minimumFractionDigits}
                  onChange={(e) =>
                    props.onOptionsChange({
                      ...props.options,
                      minimumFractionDigits: toNumber(e.target.value)
                    })
                  }
                />
                <InputInnerLabel
                  innerLabel="Max"
                  type="number"
                  step={1}
                  min={0}
                  id={`max-fraction-${props.atomId}`}
                  placeholder="Ex: 2"
                  value={props.options.maximumFractionDigits}
                  onChange={(e) =>
                    props.onOptionsChange({
                      ...props.options,
                      maximumFractionDigits: toNumber(e.target.value)
                    })
                  }
                />
              </div>
            </Column>

            <Column>
              <div className="flex items-center gap-2">
                <Label htmlFor={`min-significant-${props.atomId}`}>
                  Significant digits
                </Label>

                <InfoTooltip
                  title="Significant digits"
                  description="Leave blank to use the default value."
                  additionalContent={
                    <>
                      <ExempleTooltip
                        exampleName="Min significant digits 3"
                        input="12"
                        output="12.0"
                      />
                      <ExempleTooltip
                        exampleName="Max significant digits 3"
                        input="123456.789"
                        output="123,000"
                      />
                    </>
                  }
                />
              </div>
              <div className="flex flex-col justify-between w-full gap-2 align-top">
                <InputInnerLabel
                  innerLabel="Min"
                  id={`min-significant-${props.atomId}`}
                  type="number"
                  step={1}
                  min={0}
                  placeholder="Ex: 2"
                  value={props.options.minimumSignificantDigits}
                  onChange={(e) =>
                    props.onOptionsChange({
                      ...props.options,
                      minimumSignificantDigits: toNumber(e.target.value)
                    })
                  }
                />
                <InputInnerLabel
                  type="number"
                  step={1}
                  min={0}
                  innerLabel="Max"
                  id={`max-significant-${props.atomId}`}
                  placeholder="Ex: 2"
                  value={props.options.maximumSignificantDigits}
                  onChange={(e) =>
                    props.onOptionsChange({
                      ...props.options,
                      maximumSignificantDigits: toNumber(e.target.value)
                    })
                  }
                />
              </div>
            </Column>

            <Column>
              <div className="flex items-center gap-2">
                <Label htmlFor={`min-integer-${props.atomId}`}>
                  Integer digits
                </Label>

                <InfoTooltip
                  title="Integer digits"
                  description="Leave blank to use the default value."
                  additionalContent={
                    <>
                      <ExempleTooltip
                        exampleName="Min integer digits 3"
                        input="12.5"
                        output="012.5"
                      />
                    </>
                  }
                />
              </div>

              <div className="flex flex-col justify-between w-full gap-2 align-top">
                <InputInnerLabel
                  innerLabel="Min"
                  type="number"
                  step={1}
                  min={0}
                  id={`min-integer-${props.atomId}`}
                  placeholder="Ex: 2"
                  value={props.options.minimumIntegerDigits}
                  onChange={(e) =>
                    props.onOptionsChange({
                      ...props.options,
                      minimumIntegerDigits: toNumber(e.target.value)
                    })
                  }
                />
              </div>
            </Column>
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

const toNumber = (value: unknown): Maybe<number> => {
  if (value === '') return undefined;

  return parseWithZod(
    SharedSchema.PositiveIntegerSchema,
    Number(value),
    'POSINT-1',
    {
      withSentry: false,
      withToast: false
    }
  );
};

const renderStyleValue = (style: NumberWidgetSchema.Style) => {
  const { name, icon: StyleIcon } = NumberWidgetConfig.StyleToInfoMap[style];
  return (
    <div className="flex items-center gap-2">
      <StyleIcon size={16} />
      <p>{name}</p>
    </div>
  );
};
