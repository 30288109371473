import { ChevronDown, X } from 'lucide-react';
import { observer } from 'mobx-react';
import { DataSource, EmailData } from 'shared';

import { useDatabaseVariables } from '@hooks/useDatabaseVariables';
import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InputField } from '@atoms/input';
import { SelectField } from '@atoms/select';

import { newError } from '@/services/errors/errors';
import {
  Dropdown,
  IconButton,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Option,
  Typography
} from '@mui/joy';

import { metaOptions, stateOptions } from './email.utils';
import { DestinationField, HeaderContent } from './style';

interface EmailHeaderProps {
  atom: AtomModel<EmailData>;
  blockAtomId: string;
}

const EmailHeader = ({ atom, blockAtomId }: EmailHeaderProps) => {
  const database = useDatabaseVariables();
  const databaseOptions = Array.from(database.keys());

  const { atomStore } = useStores();

  const handleDestinationChange = (value: string) => {
    if (!atom.data.dataSource || !atom.data.dataSourceData) return; //? or fill datasource?
    atom.data.dataSource.selectedDestination = value;
    atom.data.dataSourceData.to = '';
  };

  const onRepositoryChange = (_: unknown, value: unknown): void => {
    if (!atom.data.dataSourceData || typeof value != 'string') return; //? or fill dataSourceData?

    const to = database.get(value);
    if (!to) return;

    atom.data.dataSourceData.to = to;

    const selectedVariable = atomStore.getAtomById_Unsafe(to.dataItemId);

    if (!selectedVariable) {
      newError(
        'EMAIL-qwec23',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    const blockAtom = atomStore.get(blockAtomId);

    if (!blockAtom) {
      newError('EMAIL-1qwe23', 'onVariableSelected(): blockAtom is undefined');
      return;
    }

    blockAtom.addReferenceToAtom(selectedVariable.id);
  };

  const renderDestinationData = (
    type: DataSource['selectedDestination']
  ): JSX.Element => {
    if (!atom.data.dataSourceData) {
      return <></>;
    }

    switch (type) {
      case 'state':
        return (
          // @ts-expect-error // !!! why  can the reference be a string???
          <VariableSelector selectedRef={atom.data.dataSourceData.to} />
        );
      case 'mail':
        return (
          <InputField
            value={
              typeof atom.data.dataSourceData?.to == 'string'
                ? atom.data.dataSourceData?.to
                : ''
            }
            onChange={(event) => {
              if (!atom.data.dataSourceData) return; //? or fill dataSourceData?
              atom.data.dataSourceData.to = event.target.value;
            }}
            placeholder="mail@studio.com"
            type="email"
          />
        );
      case 'repository':
        return (
          <SelectField
            onChange={onRepositoryChange}
            placeholder="Choose a value..."
          >
            {databaseOptions.map((name, index) => (
              <Option key={index} value={name} label={name}>
                {name}
              </Option>
            ))}
          </SelectField>
        );

      default:
        return <></>;
    }
  };

  return (
    <HeaderContent>
      <Typography sx={{ fontWeight: 'normal' }} level="title-sm">
        {atom.type}
      </Typography>
      <Typography sx={{ fontWeight: 'normal' }} level="title-sm">
        to
      </Typography>
      <Dropdown>
        {!atom.data.dataSource?.selectedDestination ? (
          <MenuButton size="sm" endDecorator={<ChevronDown size={14} />}>
            Destination
          </MenuButton>
        ) : (
          <DestinationField>
            {renderDestinationData(atom.data.dataSource.selectedDestination)}
            <IconButton
              size="sm"
              onClick={() => {
                if (!atom.data.dataSource) return; //? or fill dataSource?
                atom.data.dataSource.selectedDestination = '';
              }}
            >
              <X size={20} />
            </IconButton>
          </DestinationField>
        )}
        <Menu size="sm" sx={{ minWidth: 160 }}>
          {metaOptions.map((metaOption, index) => (
            <MenuItem
              key={index}
              onClick={() => handleDestinationChange(metaOption.value)}
              disabled={metaOption.disabled}
            >
              <ListItemDecorator>{metaOption.icon}</ListItemDecorator>
              {metaOption.label}
            </MenuItem>
          ))}
          <ListDivider />
          {stateOptions.map((stateOption, index) => (
            <MenuItem
              key={index}
              onClick={() => handleDestinationChange(stateOption.value)}
              disabled={stateOption.disabled}
            >
              <ListItemDecorator>{stateOption.icon}</ListItemDecorator>
              {stateOption.label}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </HeaderContent>
  );
};

export default observer(EmailHeader);
