import { useCallback, useEffect, useRef, useState } from 'react';

import BpmnColorPickerModule from 'bpmn-js-color-picker';
import Modeler from 'bpmn-js/lib/Modeler';
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import gridModule from 'diagram-js-grid';
import Canvas from 'diagram-js/lib/core/Canvas';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/diagram-js.css';

import customRendererModule from '@components/editor/draw';
import bpmnParserModule from '@components/editor/plugin/bpmn-parser';
import customMoveCanvas from '@components/editor/plugin/canvas-behavior';
import contextPadProvider from '@components/editor/plugin/context-pad';
import SuppressUndo from '@components/editor/plugin/custom-events';
import DefaultName from '@components/editor/plugin/default-name';
import customDraggin from '@components/editor/plugin/dragging';
import elementRegistryModule from '@components/editor/plugin/element-registry';
import customHandTool from '@components/editor/plugin/hand-tool';
import CustomNanoIds from '@components/editor/plugin/nano-ids';
import customPaletteModule from '@components/editor/plugin/palette';
import {
  default as appendMenuModule,
  default as createMenuModule,
  default as menuModule
} from '@components/editor/plugin/pop-menu';
import studioRules from '@components/editor/plugin/studioRules';
import ExportButton from '@components/export';
import PasswordProtectedComp from '@components/passwordProtected/passwordProtected.comp';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { BpmnModel } from '@models/bpmn.model';
import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import traceModelExtension from '@/assets/json/modelExtension.json';
import { StratumnBpmnEventHandlerCreator } from '@/services/bpmn/events/eventHandler';
import { newError } from '@/services/errors/errors';
import { CircularProgress } from '@mui/joy';

import { BpmnEditor, LoaderBox, LoaderContainer } from './editor.style';

export let IMPORT_STATUS: 'IMPORTING' | 'IMPORTED' = 'IMPORTING';
const stratumnEventHandlerCreator = new StratumnBpmnEventHandlerCreator();

const FULL_EDITOR_MODULES = [
  customPaletteModule,
  menuModule,
  appendMenuModule,
  createMenuModule,
  customRendererModule,
  contextPadProvider,
  bpmnParserModule,
  elementRegistryModule,
  SuppressUndo,
  studioRules,
  DefaultName,
  CustomNanoIds,
  {
    removeElementBehavior: ['value', {}] // disable automatic transition connexion
  }
];

const StratumnEditor = () => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [bpmnModel, setBpmnModel] =
    useState<Maybe<Modeler | NavigatedViewer>>();
  const { process } = useProcess();
  const { workflow } = useWorkflow();
  const rootStore: RootStore = useStores();

  const generateModeler = useCallback(
    (workflow: WorkflowModel) => {
      const modeler = new Modeler({
        container: editorRef.current ?? undefined,
        keyboard: {
          bindTo: document
        },
        additionalModules: [
          customDraggin,
          customHandTool,
          customMoveCanvas,
          BpmnColorPickerModule,
          gridModule,
          ...[process.draft ? [] : FULL_EDITOR_MODULES]
        ],
        moddleExtensions: process.draft
          ? {}
          : {
              // create all the custom task
              trace: traceModelExtension
            }
      });

      modeler
        .importXML(workflow.workflowXML)
        .then(({ warnings }) => {
          IMPORT_STATUS = 'IMPORTED';

          if (warnings.length) {
            console.warn(warnings);
          }

          const canvas = modeler.get<Canvas>('canvas');
          canvas.zoom('fit-viewport');

          workflow.bpmn = new BpmnModel();
          workflow.bpmn.setModeler(modeler);

          const bpmnEventHandler = stratumnEventHandlerCreator.createHandler(
            modeler,
            rootStore
          );

          bpmnEventHandler.listenToEvents(process, workflow);
          setBpmnModel(modeler);

          workflow.setCurrent();
          process.setCurrent();
        })
        .catch((error) => {
          newError('EDITOR-oXppE', error, true, {
            customMessage: 'Error: process XML corrupted'
          });
        });
    },
    [process, rootStore]
  );

  const generateNavigatedViewer = useCallback(
    (workflow: WorkflowModel) => {
      const viewer = new NavigatedViewer({
        container: editorRef.current ?? undefined,
        additionalModules: [
          customDraggin,
          customHandTool,
          customMoveCanvas,
          gridModule
        ],
        moddleExtensions: process.draft
          ? {}
          : {
              // create all the custom task
              trace: traceModelExtension
            }
      });

      viewer
        .importXML(workflow.workflowXML)
        .then(({ warnings }) => {
          if (warnings.length) {
            console.warn(warnings);
          }

          setBpmnModel(viewer);

          const canvas = viewer.get<Canvas>('canvas');
          canvas.zoom('fit-viewport');
        })
        .catch((e) => {
          newError('EDITOR-fg7jz', e, true);
        });
    },
    [process]
  );

  useEffect(() => {
    if (!process || !workflow) return;

    if (process.permission.can_edit) {
      generateModeler(workflow);
    } else {
      generateNavigatedViewer(workflow);
    }
    document.getElementsByClassName('bjs-powered-by')[0]?.remove();
  }, [process, workflow, rootStore, generateModeler, generateNavigatedViewer]);

  if (process.status == 'LOADING' || process.status == 'NEED_LOADING') {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  if (process.status == 'LOCKED') {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <PasswordProtectedComp />
      </div>
    );
  }

  if (process.status == 'RUNNING') {
    return (
      <>
        {bpmnModel && process && (
          <ExportButton
            modeler={bpmnModel}
            processName={process.name ?? 'Unnamed process'}
          />
        )}
        <div className="w-full h-[1px] bg-gray-200" />
        <BpmnEditor ref={editorRef} />
        <Outlet />
      </>
    );
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <h2>An error has occured</h2>
    </div>
  );
};

export default observer(StratumnEditor);
