import { z } from 'zod';

import { UserSchema } from '../other/user.schema';
import { PodSchema } from './pod.schema';

export const AugmentedGenerationSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  createdBy: UserSchema,
  timedOutAt: z.string().nullable(),
  job_id: z.string(),
  process_id: z.string(),
  source: z.string(),
  currentlyDeployed: z.boolean(),
  pods: z.array(PodSchema)
});

export type AugmentedGeneration = z.infer<typeof AugmentedGenerationSchema>;

export const GenerationMetadataSchema = z.object({
  numberOfGenerations: z.number()
});
export type GenerationMetadata = z.infer<typeof GenerationMetadataSchema>;

export type GetGenerationsParamsDTO = {
  page?: string;
  take?: string;
  currentlyDeployed?: string;
};

export type UpdateGenerationDTO = {
  currentlyDeployed?: boolean;
  timedOutAt?: string;
};

export enum GenerationStatus {
  RUNNING = 'Running',
  FAILED = 'Failed',
  READY = 'Ready',
  PENDING = 'Pending',
  TIMEOUT = 'Timeout'
}
