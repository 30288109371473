import {
  PasswordProtectedError,
  TooManyRequestsError
} from '@/services/errors/studio-errors';

import { STUDIO_API_URL } from './constants';

type HTTPMethodeType = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

interface NestError {
  statusCode: number;
  message: string | string[];
  error: string;
}

export interface ApiResponseEmpty {
  statusCode: number;
}

export class HTTPWrapper<T> {
  private callable_url: string;

  constructor(storeURL: string) {
    this.callable_url = `${STUDIO_API_URL}/${storeURL}`;
  }

  private async _fetch<ResponseType, BodyType = unknown>(
    method: HTTPMethodeType,
    url: string,
    body?: BodyType,
    opt?: {
      headers?: object;
    }
  ): Promise<Maybe<ResponseType>> {
    const finalURL = `${this.callable_url}${url}`;

    const res = await fetch(finalURL, {
      method,
      headers: {
        ...opt?.headers,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined
    });

    let json_data: unknown = {};

    try {
      json_data = await res.json();
    } catch (error) {
      json_data = { statusCode: res.status };
    }

    if (res.status == 423) {
      console.log('Process need password');
      throw new PasswordProtectedError();
    }

    if (res.status == 429) {
      throw new TooManyRequestsError();
    }

    return json_data as ResponseType;
  }

  private async _fetchWithForbidden<ResponseType>(
    url: string
  ): Promise<Maybe<ResponseType>> {
    try {
      const res = await fetch(`${this.callable_url}${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: undefined
      });

      const json_data: unknown = await res.json();

      if (!res.ok) {
        const error = json_data as NestError;
        if (error?.message) {
          if (Array.isArray(error.message)) throw new Error(error.message[0]);
          throw new Error(error.message);
        }
        throw Error(await res.text());
      }

      return json_data as ResponseType;
    } catch (error: unknown) {
      const response = handleError('GET', error);
      if (response && response.message) {
        return response.message as ResponseType;
      }
      throw new Error(`GET HTTP Error`);
    }
  }

  public get<ResponseType = unknown>(
    route: string,
    opt?: {
      headers?: object;
    }
  ): Promise<Maybe<ResponseType>> {
    return this._fetch<ResponseType>('GET', route, undefined, opt);
  }

  public async getWithForbiddenPossibility<ResponseType = unknown>(
    route: string
  ): Promise<Maybe<ResponseType>> {
    return this._fetchWithForbidden<ResponseType>(route);
  }
  public post<ResponseType = unknown, BodyType = unknown>(
    route: string,
    body: BodyType
  ): Promise<Maybe<ResponseType>> {
    return this._fetch('POST', route, body);
  }

  public delete(route: string): Promise<Maybe<T>> {
    return this._fetch('DELETE', route);
  }

  public put<ResponseType = unknown, BodyType = unknown>(
    route: string,
    body: BodyType
  ): Promise<Maybe<ResponseType>> {
    return this._fetch('PUT', route, body);
  }

  public patch<ResponseType = unknown, BodyType = unknown>(
    route: string,
    body: BodyType
  ): Promise<Maybe<ResponseType>> {
    return this._fetch('PATCH', route, body);
  }
}

const handleError = (
  method: HTTPMethodeType,
  error: unknown
): { message: string } | void => {
  let errorMessage: string = 'Unknown error';
  if (error instanceof Error) {
    if (error.message === 'INVALID_PASSWORD' && method === 'GET') {
      return { message: 'password_protected' };
    } else errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  // eslint-disable-next-line no-restricted-syntax
  console.error(errorMessage);
};
