import { makeObservable, observable } from 'mobx';
import { AugmentedGeneration, AugmentedGenerationSchema } from 'shared';

import { GenerationModel } from '@models/generation.model';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export default class GenerationStore extends BaseStore<GenerationModel> {
  private totalNumberOfGenerations: number = 0;
  private paginatedData: Map<number, GenerationModel[]> = new Map();
  private fetchingPages: Set<number> = new Set();
  private fetchingGenerations: Set<string> = new Set();
  private itemsPerPage: number = 7;

  constructor(rootStore: RootStore) {
    super(rootStore, GenerationModel, 'generations');

    makeObservable<
      GenerationStore,
      | 'paginatedData'
      | 'totalNumberOfGenerations'
      | 'fetchingPages'
      | 'fetchingGenerations'
    >(this, {
      paginatedData: observable,
      totalNumberOfGenerations: observable,
      fetchingPages: observable,
      fetchingGenerations: observable
    });
  }

  /* ------------------------ Class properties getters ------------------------ */
  public get totalNbOfGenerations(): number {
    return this.totalNumberOfGenerations;
  }

  public set totalNbOfGenerations(value: number) {
    this.totalNumberOfGenerations = value;
  }

  public get fetchingPagesSet(): Set<number> {
    return this.fetchingPages;
  }

  public get fetchingGenerationsSet(): Set<string> {
    return this.fetchingGenerations;
  }

  public getPageData(page: number, take?: number): GenerationModel[] {
    if (!this.paginatedData.has(page) && !this.fetchingPages.has(page)) {
      this.fetchPage(page, take);
    }
    return this.getPageDataOrEmpty(page);
  }

  public toArray(): GenerationModel[] {
    return Array.from([
      ...this.paginatedData.values(),
      ...this.data.values()
    ]).flatMap((flattedArray) => {
      return flattedArray;
    });
  }

  public get(id: string): GenerationModel | undefined {
    const cachedGeneration = this.toArray().find(
      (generation) => generation.id === id
    );
    if (cachedGeneration) return cachedGeneration;

    this.pollGeneration(id).then((polledGeneration) => {
      if (!polledGeneration) return;

      const newGeneration = new GenerationModel(
        this,
        polledGeneration.id,
        polledGeneration.createdBy,
        polledGeneration.createdAt,
        polledGeneration.job_id,
        polledGeneration.process_id,
        polledGeneration.source,
        polledGeneration.pods,
        polledGeneration.currentlyDeployed,
        polledGeneration.timedOutAt
      );
      this.set(id, newGeneration);
    });
    return;
  }

  public get NbOfItemsPerPage(): number {
    return this.itemsPerPage;
  }

  /* ------------------------ Class properties getters ------------------------ */
  private loadPageData(
    augmentedGenerations: AugmentedGeneration[],
    correspondingPage: number
  ) {
    const parsedGenerations: GenerationModel[] = [];
    for (const augmentedGenerationDTO of augmentedGenerations) {
      const parsedGenerationData = parseWithZod(
        AugmentedGenerationSchema,
        augmentedGenerationDTO,
        'GNST-DzRG6'
      );
      if (!parsedGenerationData) continue;

      const newGeneration = new GenerationModel(
        this,
        parsedGenerationData.id,
        parsedGenerationData.createdBy,
        parsedGenerationData.createdAt,
        parsedGenerationData.job_id,
        parsedGenerationData.process_id,
        parsedGenerationData.source,
        parsedGenerationData.pods,
        parsedGenerationData.currentlyDeployed,
        parsedGenerationData.timedOutAt
      );

      parsedGenerations.push(newGeneration);
    }
    //! We assume the backend sends the generations sorted by createdAt
    this.paginatedData.set(correspondingPage, parsedGenerations);
  }

  public preprendGenerationToPage(
    augmentedGeneration: AugmentedGeneration,
    page: number
  ) {
    const parsedGenerationData = parseWithZod(
      AugmentedGenerationSchema,
      augmentedGeneration,
      'GNST-2MiL1'
    );

    if (!parsedGenerationData) {
      newError('GNST-EiHux', 'Failed to parse generation data');
      return;
    }

    const newGeneration = new GenerationModel(
      this,
      parsedGenerationData.id,
      parsedGenerationData.createdBy,
      parsedGenerationData.createdAt,
      parsedGenerationData.job_id,
      parsedGenerationData.process_id,
      parsedGenerationData.source,
      parsedGenerationData.pods,
      parsedGenerationData.currentlyDeployed,
      parsedGenerationData.timedOutAt
    );

    this.handlePageOverflow(page, newGeneration);

    return newGeneration;
  }

  /* --------------------------------- Helpers -------------------------------- */
  public updatePreviouslyDeployedGenerationsInCache(
    currentlyDeployedId: string
  ) {
    const currentlyDeployedGenerationsCached = this.toArray().filter(
      (generation) =>
        generation.getCurrentlyDeployed() &&
        generation.id !== currentlyDeployedId
    );

    for (const generation of currentlyDeployedGenerationsCached) {
      generation.setCurrentlyDeployed(false);
    }
  }

  public async pollGeneration(
    id: string
  ): Promise<AugmentedGeneration | undefined> {
    if (this.fetchingGenerations.has(id)) return;

    this.fetchingGenerations.add(id);

    const polledGenerationDTO = await this.httpWrapper.get<AugmentedGeneration>(
      `/${id}`
    );

    const parsedGeneration = parseWithZod(
      AugmentedGenerationSchema,
      polledGenerationDTO,
      'GNST-n5wje'
    );

    this.fetchingGenerations.delete(id);

    if (!parsedGeneration) {
      newError('GNST-9GPnC', 'Failed to parse polled generation');
      return;
    }

    return parsedGeneration;
  }

  private async fetchPage(page: number, take?: number) {
    this.fetchingPages.add(page);

    const fetchedGenerations = await this.httpWrapper.get<
      AugmentedGeneration[]
    >(`?page=${page}&take=${take ?? this.itemsPerPage}`);

    if (!fetchedGenerations) {
      newError('GNST-4PC08', `Failed to fetch page ${page} of generations`);
      return;
    }

    this.loadPageData(fetchedGenerations, page);

    this.fetchingPages.delete(page);
  }

  private getMaxPageNumber(): number {
    return Math.max(...this.paginatedData.keys());
  }

  private getPageDataOrEmpty(page: number): GenerationModel[] {
    return this.paginatedData.get(page) || [];
  }

  private hasSpaceInPage(pageData: GenerationModel[]): boolean {
    return pageData.length < this.itemsPerPage;
  }

  private addGenerationToPage(page: number, generation: GenerationModel): void {
    const pageData = this.getPageDataOrEmpty(page);

    this.paginatedData.set(page, [generation, ...pageData]);
  }

  private isLastPage(page: number): boolean {
    return page === this.getMaxPageNumber();
  }

  private createNewPage(newPage: number, generation: GenerationModel): void {
    this.paginatedData.set(newPage, [generation]);
  }

  private isNotLoaded(page: number): boolean {
    return !this.paginatedData.has(page);
  }

  private moveGenerationBetweenPages(
    currentPage: number,
    incomingGeneration: GenerationModel
  ): GenerationModel {
    const pageData = this.getPageDataOrEmpty(currentPage);
    const outgoingGeneration = pageData.pop()!;

    this.paginatedData.set(currentPage, [incomingGeneration, ...pageData]);

    return outgoingGeneration;
  }

  private invalidateCacheFromPage(startPage: number): void {
    for (let page = startPage; page <= this.getMaxPageNumber(); page++) {
      this.paginatedData.delete(page);
    }
  }

  private handlePageOverflow(
    startPage: number,
    overflowGeneration: GenerationModel
  ): void {
    let currentPage = startPage;
    let generationToMove = overflowGeneration;
    const maximumPage = this.getMaxPageNumber();

    while (currentPage <= maximumPage) {
      if (this.isNotLoaded(currentPage)) {
        this.invalidateCacheFromPage(currentPage);
        return;
      }

      const currentPageData = this.getPageDataOrEmpty(currentPage);

      if (this.hasSpaceInPage(currentPageData)) {
        this.addGenerationToPage(currentPage, generationToMove);
        return;
      }

      generationToMove = this.moveGenerationBetweenPages(
        currentPage,
        generationToMove
      );

      if (this.isLastPage(currentPage)) {
        this.createNewPage(currentPage + 1, generationToMove);
      }

      currentPage++;
    }
  }
}
