import { SettingsHeaderContainer } from './settings.style';
import { SettingDescription, SettingTitle } from './style';

type SettingHeaderProps = {
  title: string;
  description: string;
};

const SettingHeader = ({ title, description }: SettingHeaderProps) => {
  return (
    <>
      <SettingsHeaderContainer>
        <SettingTitle>{title}</SettingTitle>
        <SettingDescription>{description}</SettingDescription>
      </SettingsHeaderContainer>
      <div className="border-b border-solid border-zinc-200	-mx-144 mb-3"></div>
    </>
  );
};

export default SettingHeader;
