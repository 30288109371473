import { Trash2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { AtomReference, AtomType, PrefillData } from 'shared';

import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';

import { newError } from '@/services/errors/errors';
import { FormControl, FormLabel } from '@mui/joy';

type Props = {
  atom: AtomModel<PrefillData>;
};
export const Prefill = observer(({ atom }: Props) => {
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;
  const { atomStore } = useStores();

  if (atom.data.prefill) {
    const tmpSelectedVariable = atomStore.getAtomById(
      atom.data.prefill.dataItemId,
      // ? defining blockType would introduce a circular dependency
      atom.data.prefill.blockType as AtomType
    );

    if (tmpSelectedVariable instanceof Error) {
      newError('PREF-1000', "Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const onVariableSelected = (
    seletedVariableReference: Maybe<AtomReference>
  ) => {
    if (!seletedVariableReference) {
      newError(
        'PREF-2000',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    atom.data.prefill = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError(
        'PREF-3000',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    atom.addReferenceToAtom(selectedVariable.id);
  };

  const removePrefill = () => {
    atom.data.prefill = undefined;

    atom.removeReferenceToAtom(selectedVariable?.id ?? '');
    selectedVariable = undefined;
  };

  return (
    <FormControl size="sm">
      <FormLabel>Prefill with a state variable</FormLabel>
      <div className="flex items-center gap-2">
        <VariableSelector
          value={selectedVariable?.data.title}
          onSelected={onVariableSelected}
        />
        {selectedVariable && (
          <div
            className="cursor-pointer rounded-md p-2 text-gray-600 hover:bg-red-50 hover:text-red-700"
            onClick={removePrefill}
          >
            <Trash2 size={17} />
          </div>
        )}
      </div>
    </FormControl>
  );
});
