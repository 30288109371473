import React from 'react';

import { toJS } from 'mobx';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  DRAFT_PROCESS_MENU_ITEMS,
  STRATUMN_PROCESS_MENU_ITEMS
} from '@pages/Settings/menu';

import useProcess from '@hooks/useProcess';

import { cn } from '@/lib/utils';

export const SubHeader = () => {
  const { process } = useProcess();

  const isDraft = process?.draft;

  const MENU_ITEMS = isDraft
    ? DRAFT_PROCESS_MENU_ITEMS
    : STRATUMN_PROCESS_MENU_ITEMS;

  const [tabBoundingBox, setTabBoundingBox] = React.useState<Maybe<DOMRect>>();
  const [wrapperBoundingBox, setWrapperBoundingBox] =
    React.useState<Maybe<DOMRect>>();
  const [highlightedTab, setHighlightedTab] = React.useState<boolean | null>(
    null
  );
  const [isHoveredFromNull, setIsHoveredFromNull] = React.useState(true);

  const highlightRef = React.useRef(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const repositionHighlight = (e: any, tab: boolean) => {
    setTabBoundingBox(
      // eslint-disable-next-line
      e.target!.getBoundingClientRect() as unknown as Maybe<DOMRect>
    );
    setWrapperBoundingBox(wrapperRef.current?.getBoundingClientRect());
    setIsHoveredFromNull(!highlightedTab);
    setHighlightedTab(tab);
  };

  const resetHighlight = () => {
    setHighlightedTab(null);
  };

  const highlightStyles: React.CSSProperties = {};

  if (tabBoundingBox && wrapperBoundingBox) {
    highlightStyles.transitionDuration = isHoveredFromNull ? '0ms' : '150ms';
    highlightStyles.width = `${tabBoundingBox.width}px`;
    highlightStyles.opacity = highlightedTab ? 1 : 0;
    highlightStyles.transform = `translate(${
      tabBoundingBox.left - wrapperBoundingBox.left
    }px)`;
  }

  if (process.status == 'LOADING') {
    return null;
  }

  return (
    <div
      ref={wrapperRef}
      className="w-full bg-white pl-[23px] flex flex-row gap-x-1 border-b border-solid border-gray-200"
      onMouseLeave={resetHighlight}
    >
      <TabsHighlight
        className="bg-gray-200"
        ref={highlightRef}
        style={highlightStyles}
      />
      {MENU_ITEMS.map(({ name, link }) => (
        <NavLink
          to={link}
          onMouseOver={(ev) => repositionHighlight(ev, true)}
          className={({ isActive }) =>
            cn([
              'relative text-[14px] py-2 font-normal transition-all',
              isActive
                ? 'text-gray-900 border-b-2 border-solid border-gray-700'
                : 'text-gray-500 border-b-2 border-solid border-transparent'
            ])
          }
        >
          <div className="transition-all group-[.active]:bg-gray-300 group-[.active]:text-gray-900  rounded-md px-3 py-1 hover:text-gray-800">
            {name}
          </div>
        </NavLink>
      ))}
    </div>
  );
};

const TabsHighlight = styled.div`
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 4px;
  height: 32px;
  transition: 0.15s ease;
  transition-property: width, transform, opacity;
`;
