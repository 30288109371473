import ActionStore from '@stores/action.store';
import AtomStore from '@stores/atom.store';
import CodeStore from '@stores/code.store';
import { DNDStore } from '@stores/dnd.store';
import EventStore from '@stores/event.store';
import GatewayStore from '@stores/gateway.store';
import ModalStore from '@stores/modal.store';
import { NotificationTemplateStore } from '@stores/notificationTemplate.store';
import { ProcessStore } from '@stores/process.store';
import { TransitionStore } from '@stores/transition.store';
import { VariablesStore } from '@stores/variables.store';
import { WorkflowStore } from '@stores/workflow.store';

import DatabaseStore from './database.store';
import GenerationStore from './generation.store';

export default class RootStore {
  dndStore: DNDStore;
  actionStore: ActionStore;
  variablesStore: VariablesStore;
  processStore: ProcessStore;
  transitionStore: TransitionStore;
  workflowStore: WorkflowStore;
  notificationTemplateStore: NotificationTemplateStore;
  atomStore: AtomStore;
  modalStore: ModalStore;
  codeStore: CodeStore;
  gatewayStore: GatewayStore;
  eventStore: EventStore;
  databaseStore: DatabaseStore;
  generationStore: GenerationStore;

  constructor() {
    this.dndStore = new DNDStore(this);
    this.dndStore.api_enabled = true;
    this.actionStore = new ActionStore(this);
    this.variablesStore = new VariablesStore(this);
    this.processStore = new ProcessStore(this);
    this.transitionStore = new TransitionStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.notificationTemplateStore = new NotificationTemplateStore(this);
    this.atomStore = new AtomStore(this);
    this.modalStore = new ModalStore(this);
    this.codeStore = new CodeStore(this);
    this.gatewayStore = new GatewayStore(this);
    this.eventStore = new EventStore(this);
    this.databaseStore = new DatabaseStore(this);
    this.generationStore = new GenerationStore(this);
  }
}
