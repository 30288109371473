import { LucideIcon, Mail } from 'lucide-react';
import { observer } from 'mobx-react';
import { AtomReference, type TemplateVariable } from 'shared';

import useStores from '@hooks/useStore';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';

import { newError } from '@/services/errors/errors';

import { TemplateVariableContainer, TemplateVariableField } from './style';

export const EmailIcon: LucideIcon = Mail;

export interface TemplateVariableProps {
  template: TemplateVariable;
  blockAtomId: string;
}

const TemplateVariable = ({ template, blockAtomId }: TemplateVariableProps) => {
  const { atomStore } = useStores();

  const onVariableSelected = (
    seletedVariableReference: Maybe<AtomReference>
  ) => {
    if (!seletedVariableReference) {
      return;
    }

    template.value = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError(
        'TV-przx2d',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    const blockAtom = atomStore.get(blockAtomId);

    if (!blockAtom) {
      newError(
        'TV-cbgTP',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    blockAtom.addReferenceToAtom(selectedVariable.id);
  };

  return (
    <TemplateVariableContainer>
      <TemplateVariableField>{template.variable}</TemplateVariableField>
      <p>{'-->'}</p>
      <VariableSelector
        onSelected={onVariableSelected}
        selectedRef={template.value}
      />
    </TemplateVariableContainer>
  );
};

export default observer(TemplateVariable);
