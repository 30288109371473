import { makeObservable, observable } from 'mobx';
import {
  BaseWorkflow,
  DraftProcess,
  GenericStudioProcess,
  GenericStudioProcessSchema,
  Permission,
  StratumnProcess
} from 'shared';

import Model, { ModelError } from '@models/base/base.model';

import AsyncStore, { IModelStatus } from '@stores/base/async.store';
import { ProcessStore } from '@stores/process.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { PasswordProtectedError } from '@/services/errors/studio-errors';
import { ProcessImageTag, UserRoles } from '@/types/process.types';
import { HTTPWrapper } from '@/utils/http';
import { parseWithZod } from '@/utils/parseZodSchema';

import { WorkflowModel } from './workflow.model';

export interface InputProcess {
  name: string;
  draft: boolean;
  template: string;
}

export interface Collaborator {
  id?: string;
  name?: string;
  role: UserRoles;
  avatar?: string;
  email: string;
}

export class ProcessModel extends Model {
  httpWrapper: HTTPWrapper<Model>;
  rootStore: RootStore;
  status: IModelStatus = 'NEED_LOADING';
  password?: string;

  isPublished: boolean = false;
  name: Maybe<string> = '';
  createdAt: string = ''; // timestamp
  updatedAt: string = ''; // timestamp
  iconUrl: string =
    'https://media.licdn.com/dms/image/sync/D4E27AQHnLDObSOv_tQ/articleshare-shrink_800/0/1719566110916?e=2147483647&v=beta&t=-qRLHYFfOkL87wZiRTTr-JRcZzAzUylB1KrxkO8k-M8';
  draft: boolean = false;
  workflowIds?: { id: string; published_id: string | null }[];
  password_protected?: boolean;
  permission: Permission = {
    can_delete: false,
    can_edit: false,
    can_leave: false,
    can_view: false
  };
  is_public?: boolean;
  user_role?: UserRoles;
  collaborators?: Collaborator[];
  private image?: string;

  constructor(
    store: AsyncStore<ProcessModel>,
    id: Model['id'],
    loading?: boolean
  ) {
    super(store, id, loading);

    this.rootStore = this.store.rootStore;
    this.httpWrapper = this.store.httpWrapper;

    makeObservable(this, {
      name: observable,
      workflowIds: observable,
      password_protected: observable,
      is_public: observable,
      user_role: observable,
      collaborators: observable,
      status: observable
    });
  }

  setCurrent() {
    (this.store as ProcessStore).setCurrentProcess(this.id);
  }

  async fetchProcess() {
    let processRaw;
    const id = this.id;

    this.status = 'LOADING';

    try {
      processRaw = await this.httpWrapper.get(`/${id}`, {
        headers: {
          'X-Studio-Password': this.password && this.password.length > 0
        }
      });
    } catch (error) {
      if (error instanceof PasswordProtectedError) {
        this.status = 'LOCKED';
        return;
      }
    }

    const loadedProcess = parseWithZod(
      GenericStudioProcessSchema,
      processRaw,
      'PROCS-veX4f'
    );

    if (!loadedProcess) {
      const errorMessage = `Error while fetching the process with id: ${id}`;
      newError('PROCS-lMQ4B', errorMessage, true, {
        customMessage: 'Error while loading the process'
      });
      this.status = 'ERROR';
      throw new Error(errorMessage);
    }

    this.loadCommonObject(loadedProcess);

    if (loadedProcess.draft) {
      console.log('Loading draft process');
      this.loadDraft(loadedProcess);
    } else {
      console.log('Loading studio process');
      this.loadProcess(loadedProcess);
    }

    // this.rootStore.dndStore.store_ready = true;
    this.status = 'RUNNING';
  }

  loadCommonObject = (generic: GenericStudioProcess) => {
    const atomStore = this.rootStore.atomStore;
    atomStore.loadAtoms(generic.atom);

    this.name = generic.name;
    this.permission = generic.permission;
    // this.permission.can_edit = false;
    this.createdAt = generic.createdAt;
    this.updatedAt = generic.updatedAt;
    if (generic.icon.includes('http')) {
      this.iconUrl = generic.icon;
    }
    this.draft = generic.draft;
    this.image = generic.image ?? '';

    for (const database of generic.databases) {
      this.rootStore.databaseStore.loadDatabaseToStore(database);
    }

    this.rootStore.generationStore.totalNbOfGenerations =
      generic.generationsMetadata.numberOfGenerations;
    this.rootStore.generationStore.getPageData(1);
  };

  loadDraft = (loadedDraft: DraftProcess) => {
    const workflowStore = this.rootStore.workflowStore;
    const workflowIds: Pick<BaseWorkflow, 'id' | 'published_id'>[] = [];

    for (const workflow of loadedDraft.workflows) {
      const tmp = workflowStore.loadBaseWorkflow(workflow);
      workflowIds.push(tmp);
    }

    this.workflowIds = workflowIds;
  };

  loadProcess = (loadedProcess: StratumnProcess) => {
    const workflowStore = this.rootStore.workflowStore;
    const transitionStore = this.rootStore.transitionStore;
    const notificationTemplateStore = this.rootStore.notificationTemplateStore;

    /* ------------------------- Handle workflows ------------------------- */
    const workflowIds = workflowStore.loadWorkflows(loadedProcess.workflows);

    /* --------------------------- Handle transitions --------------------------- */
    transitionStore.loadTransitions(loadedProcess.Transition);

    /* -------------------------- Handle notification templates ------------------*/
    notificationTemplateStore.loadNotificationTemplates(
      loadedProcess.notificationTemplates
    );

    /* ---------------------- Finish process instanciation ---------------------- */

    this.isPublished = loadedProcess.published;
    this.workflowIds = workflowIds;
  };

  get processImage() {
    return this.image;
  }

  get imageUrl() {
    return this.image?.split(':').slice(0, -1).join(':');
  }

  get imageTag() {
    const tag = this.image?.split(':').at(-1);
    if (!tag) return undefined;
    if (!Object.values(ProcessImageTag).includes(tag as ProcessImageTag))
      return undefined;
    return tag as ProcessImageTag;
  }

  set imageTag(newTag: ProcessImageTag | undefined) {
    if (!newTag) {
      return;
    }
    this.image = this.imageUrl?.concat(':').concat(newTag);
  }

  get workflows(): WorkflowModel[] {
    if (!this.workflowIds) return [];
    const workflows: WorkflowModel[] = [];
    this.workflowIds.forEach((workflowId) => {
      const workflow = this.store.rootStore.workflowStore.get(workflowId.id);
      if (workflow) workflows.push(workflow);
    });
    return workflows;
  }

  get isLoading() {
    return this.status === 'LOADING';
  }

  get updateDate(): Date {
    return new Date(this.updatedAt);
  }

  get toJSON() {
    return {
      name: this.name,
      is_public: this.is_public,
      password: this.password,
      image: this.image
    };
  }

  get errors(): ModelError[] {
    return [];
  }
}
