import styled from 'styled-components';

export const GenerationDetailsContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  background-clip: padding-box;
  height: auto;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 45px;
`;
