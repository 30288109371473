import { CustomCellRendererProps } from 'ag-grid-react';
import {
  AtomReference,
  HydratedRowAtomReference
} from 'shared/src/atom/atomReference.schema';

import { GroupedBadges } from '@components/database/groupedBadges';

import { useHydratedRowAtomReference } from '@hooks/useHydratedRowAtomReference';

export const BadgeRenderer = (
  params: CustomCellRendererProps<any, AtomReference[] | undefined>
) => {
  const hydratedReferences: HydratedRowAtomReference[] = [];

  if (params.value) {
    hydratedReferences.push(...useHydratedRowAtomReference(params.value));
  }

  return (
    <GroupedBadges
      selectedOptions={hydratedReferences.map((hydratedRef) => {
        return {
          label: hydratedRef.displayedValue,
          value: hydratedRef.dataItemId
        };
      })}
    ></GroupedBadges>
  );
};
