import styled, { css } from 'styled-components';

interface GenerationContainerProps {
  isFirst?: boolean;
  isLast?: boolean;
}

export const GenerationContainer = styled.div<GenerationContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  background-clip: padding-box;
  height: auto;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    `}
`;
