import { z } from 'zod';

import { PodSchema } from './pod.schema';

/* ---------------------------------- ENUMS --------------------------------- */

export enum JobStatus {
  RECEIVED = 'RECEIVED',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

/* ---------------------------------- DTO FOR METIER JOBS ---------------------------------- */
export const jobSchema = <T extends z.ZodRawShape>(
  typeSchema: z.ZodObject<T>
) =>
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    cron_expression: z.string().optional(),
    data: typeSchema,
    status: z.enum([
      JobStatus.COMPLETED,
      JobStatus.IN_PROGRESS,
      JobStatus.RECEIVED,
      JobStatus.SCHEDULED
    ]),
    created_by: z.string(),
    starts_at: z.string().optional(),
    ends_at: z.string().optional(),
    sentry_dsn: z.string().optional(),
    sentry_monitor: z.string().optional(),
    image: z.string().optional(),
    pods: z.array(PodSchema)
  });

export const createJobSchema = <T extends z.ZodRawShape>(
  typeSchema: z.ZodObject<T>
) =>
  jobSchema(typeSchema).omit({
    id: true,
    status: true,
    pods: true
  });

export const etlDataSchema = z.object({
  rs_database: z.string(),
  schema: z.string(),
  wf_ids: z.array(z.number()),
  filters: z.array(z.string()).optional(),
  column_filter: z.array(z.string()).optional()
});
export type EtlDataType = z.infer<typeof etlDataSchema>;

export const webhookDataSchema = z.object({
  webhook_url: z.string(),
  webhook_payload: z.string()
});
export type WebhookDataType = z.infer<typeof etlDataSchema>;

export const compilerDataSchema = z
  .object({
    workflows: z.record(z.string()).optional(),
    repositories: z.record(z.any())
  })
  .strict();
export type CompilerDataType = z.infer<typeof compilerDataSchema>;

const etlJobSchema = jobSchema(etlDataSchema);
const createEtlJobSchema = createJobSchema(etlDataSchema);

const compilerJobSchema = jobSchema(compilerDataSchema);
const createCompilerJobSchema = createJobSchema(compilerDataSchema);

export type EtlJobDTO = z.infer<typeof etlJobSchema>;
export type CreateEtlJobDTO = z.infer<typeof createEtlJobSchema>;

export type CompilerJobDTO = z.infer<typeof compilerJobSchema>;
export type CreateCompilerJobDTO = z.infer<typeof createCompilerJobSchema>;

const webhookJobSchema = jobSchema(webhookDataSchema);
export type WebhookJobDTO = z.infer<typeof webhookJobSchema>;

export type AllJobsDTO = EtlJobDTO | CompilerJobDTO | WebhookJobDTO;
