import { StudioUser, UserSchema } from 'shared';

import { GraphQLService } from '@/services/graphql.service';
import { ACCOUNT_API_URL, ACCOUNT_UI_URL, CLIENT_ID } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';
import { gql } from '@apollo/client';

class AccountServiceClass extends GraphQLService {
  static #instance: AccountServiceClass;

  private constructor() {
    super(ACCOUNT_API_URL + '/graphql');
  }

  public static get instance(): AccountServiceClass {
    if (!AccountServiceClass.#instance) {
      AccountServiceClass.#instance = new AccountServiceClass();
    }

    return AccountServiceClass.#instance;
  }

  async getMe(): Promise<Maybe<StudioUser>> {
    const data = await this.query<{ me: StudioUser }>({
      query: gql`
        query getUser {
          me {
            rowId
            avatar
            email
            name
            firstName
            lastName
            isSuperuser
          }
        }
      `,
      variable: {}
    });

    if (!data || !data.me) {
      const pathname = window.location.pathname;
      const isDraftEditor = pathname.endsWith('draft-editor');
      if (isDraftEditor) {
        return;
      }
      redirectToAccountUILogin();
      return;
    }

    const parsedUser = parseWithZod(UserSchema, data?.me, 'ACCNT-QbEIQ');
    if (!parsedUser) {
      redirectToAccountUILogin();
      return;
    }

    const user = parsedUser;
    return user;
  }
}

export const redirectToAccountUILogin = (): void => {
  location.href = `${ACCOUNT_UI_URL}/login?clientId=${CLIENT_ID}&redirectUri=${window.location.href}`;
};

const AccountService = AccountServiceClass.instance;

export default AccountService;
